import { Colors as NVColors } from '@nv/react-commons/src/Themes'
import { Colors } from 'themes'
import {
  CustomsDescription,
  GoodsValue,
  Quantity,
  ParcelWeight,
  GSTItem,
  LVGItem,
  ItemColor
} from 'containers/FPLOrderRequestList/Fields'

export const ORDER_REQUEST_STATUS = {
  PROCESSING: 0,
  CREATED: 1,
  INVALID: 2,
  SERVER_ERROR: 3,
  DELETED: 4,
  ALL: 5 // handle by FE only
}

export const ORDER_REQUEST_STATUS_LABEL = {
  [ORDER_REQUEST_STATUS.ALL]: 'All',
  [ORDER_REQUEST_STATUS.PROCESSING]: 'Processing',
  [ORDER_REQUEST_STATUS.CREATED]: 'Created',
  [ORDER_REQUEST_STATUS.INVALID]: 'Error-Invalid',
  [ORDER_REQUEST_STATUS.SERVER_ERROR]: 'Error',
  [ORDER_REQUEST_STATUS.DELETED]: 'Deleted'
}

export const ORDER_REQUEST_STATUS_COLOR = {
  [ORDER_REQUEST_STATUS.PROCESSING]: Colors.N300,
  [ORDER_REQUEST_STATUS.CREATED]: Colors.G300,
  [ORDER_REQUEST_STATUS.INVALID]: Colors.Y300,
  [ORDER_REQUEST_STATUS.SERVER_ERROR]: NVColors.nvPriRed,
  [ORDER_REQUEST_STATUS.DELETED]: Colors.N500
}

export const ORDER_REQUEST_STATUS_MENU = [
  {
    localizeKey: ORDER_REQUEST_STATUS_LABEL[ORDER_REQUEST_STATUS.ALL],
    label: ORDER_REQUEST_STATUS_LABEL[ORDER_REQUEST_STATUS.ALL],
    key: ORDER_REQUEST_STATUS.ALL
  },
  {
    localizeKey: ORDER_REQUEST_STATUS_LABEL[ORDER_REQUEST_STATUS.PROCESSING],
    label: ORDER_REQUEST_STATUS_LABEL[ORDER_REQUEST_STATUS.PROCESSING],
    key: ORDER_REQUEST_STATUS.PROCESSING
  },
  {
    localizeKey: ORDER_REQUEST_STATUS_LABEL[ORDER_REQUEST_STATUS.CREATED],
    label: ORDER_REQUEST_STATUS_LABEL[ORDER_REQUEST_STATUS.CREATED],
    key: ORDER_REQUEST_STATUS.CREATED
  },
  {
    localizeKey: ORDER_REQUEST_STATUS_LABEL[ORDER_REQUEST_STATUS.INVALID],
    label: ORDER_REQUEST_STATUS_LABEL[ORDER_REQUEST_STATUS.INVALID],
    key: ORDER_REQUEST_STATUS.INVALID
  },
  {
    localizeKey: ORDER_REQUEST_STATUS_LABEL[ORDER_REQUEST_STATUS.SERVER_ERROR],
    label: ORDER_REQUEST_STATUS_LABEL[ORDER_REQUEST_STATUS.SERVER_ERROR],
    key: ORDER_REQUEST_STATUS.SERVER_ERROR
  }
]

export const ACTION_MENU_LABEL = {
  FIX: 'order_fix',
  ROLLBACK: 'order_rollback',
  RETRY: 'order_retry',
  VIEW: 'order_view',
  PRINT: 'order_print',
  RECOVER: 'order_recover'
}

export const BATTERY_TYPE = {
  NO_BATTERY: 0,
  LITHIUM_ION: 1,
  LITHIUM: 2,
  ALKALINE: 3,
  OTHER: 4
}

export const BATTERY_TYPE_LABEL = {
  [BATTERY_TYPE.NO_BATTERY]: 'battery_no_battery',
  [BATTERY_TYPE.LITHIUM_ION]: 'battery_lithium_ion',
  [BATTERY_TYPE.LITHIUM]: 'battery_lithium',
  [BATTERY_TYPE.ALKALINE]: 'battery_alkaline',
  [BATTERY_TYPE.OTHER]: 'battery_other'
}

export const BATTERY_PACKING = {
  NO_BATTERY: 0,
  BATTERY_ONLY: 1,
  WITH_EQUIPMENT: 2,
  INSIDE_EQUIPMENT: 3
}

export const BATTERY_PACKING_LABEL = {
  [BATTERY_PACKING.NO_BATTERY]: 'battery_packing_no_battery',
  [BATTERY_PACKING.BATTERY_ONLY]: 'battery_packing_battery_only',
  [BATTERY_PACKING.WITH_EQUIPMENT]: 'battery_packing_with_equipment',
  [BATTERY_PACKING.INSIDE_EQUIPMENT]: 'battery_packing_inside_equipment'
}

export const DEFAULT_DELIVERY_TIME_VALUE = 5

export const DELIVERY_TIME_VALUE = {
  '09:00-12:00': 0,
  '12:00-15:00': 1,
  '15:00-18:00': 2,
  '18:00-22:00': 3,
  '09:00-18:00': 4,
  '09:00-22:00': 5
}
export const DELIVERY_TIME_PARAMS = {
  0: '09:00-12:00',
  1: '12:00-15:00',
  2: '15:00-18:00',
  3: '18:00-22:00',
  4: '09:00-18:00',
  5: '09:00-22:00'
}
export const DAYS_PER_YEAR = 365
export const WEEKS_PER_YEAR = 52

export const NAVIGATION_BUTTON_TYPES = {
  NEW_ORDER: 'NEW_ORDER',
  BACK_TO_BATCH_LIST: 'BACK_TO_BATCH_LIST'
}

export const GOODS_CURRENCY_DATA = {
  IDR: 'idr',
  MYR: 'myr',
  PHP: 'php',
  SGD: 'sgd',
  THB: 'thb',
  USD: 'usd',
  VND: 'vnd'
}

export const SOURCE_PRINT_MODAL = {
  LIST: 'List',
  ORDER_DETAIL: 'Order detail'
}

export const PRINTED_STATUS = {
  SUCCESS: 'Success',
  ERROR: 'Error'
}

export const ITEMISED_LEVEL_BY_COUNTRY = {
  SG: [
    [GoodsValue, 5],
    [GSTItem, 5],
    [Quantity, 3],
    [CustomsDescription, 11]
  ],
  MY: [
    [GoodsValue, 5],
    [LVGItem, 5],
    [Quantity, 3],
    [CustomsDescription, 11]
  ],
  ID: [
    [GoodsValue, 5],
    [Quantity, 3],
    [CustomsDescription, 16]
  ],
  VN: [
    [GoodsValue, 5],
    [Quantity, 3],
    [CustomsDescription, 16]
  ],
  TH: [
    [GoodsValue, 4],
    [Quantity, 3],
    [ItemColor, 5],
    [ParcelWeight, 4, true],
    [CustomsDescription, 8]
  ]
}

export const PRINT_AWB_MODAL = {
  PAGE_LAYOUT: 2,
  LIMIT_ROWS: 50,
  DISABLE_DELAY_TIME: 2000,
  BLOB_TYPE: 'application/pdf',
  INVALID_STATUS_MESSAGE: 'print_invalid_status',
  LIMIT_ROWS_MESSAGE: 'print_limit_row',
  NO_ITEMS_MESSAGE: 'print_no_item'
}

export const FRIENDLY_FIELD_NAME = {
  requested_tracking_id: 'international_requested_tracking_id',
  service_code: 'service_code',
  source_order_id: 'source_order_id',
  source_reference_id: 'source_order_id',
  'from.name': 'from_name',
  'from.address_line1': 'from.address_line1',
  'from.address_line2': 'from.address_line2',
  'from.address_line3': 'from.address_line3',
  'from.address_line4': 'from.address_line4',
  'from.city': 'from.city',
  'from.state_province': 'from.state_province',
  'from.post_code': 'from.post_code',
  'from.country_code': 'from.country_code',
  'from.contact_email': 'from.contact_email',
  'from.contact_number': 'from.contact_number',
  'to.name': 'to.name',
  'to.address_line1': 'to.address_line1',
  'to.address_line2': 'to.address_line2',
  'to.address_line3': 'to.address_line3',
  'to.address_line4': 'to.address_line4',
  'to.city': 'to.city',
  'to.state_province': 'to.state_province',
  'to.post_code': 'to.post_code',
  'to.country_code': 'to.country_code',
  'to.contact_email': 'to.contact_email',
  'to.contact_number': 'to.contact_number',
  'return.name': 'return.name',
  'delivery.delivery_start_date': 'delivery.delivery_start_date',
  'delivery.delivery_timeslot': 'delivery.delivery_timeslot',
  'delivery.delivery_timeslot.start_time': 'delivery.delivery_timeslot.start_time',
  'delivery.delivery_timeslot.end_time': 'delivery.delivery_timeslot.end_time',
  'delivery.delivery_instructions': 'delivery.delivery_instructions',
  'delivery.cash_on_delivery': 'delivery.cash_on_delivery',
  'delivery.insured_value': 'delivery.insured_value',
  'pickup.pickup_date': 'pickup.pickup_date',
  'pickup.pickup_timeslot': 'pickup.pickup_timeslot',
  'pickup.pickup_timeslot.start_time': 'pickup.pickup_timeslot.start_time',
  'pickup.pickup_timeslot.end_time': 'pickup.pickup_timeslot.end_time',
  'pickup.pickup_instructions': 'pickup.pickup_instructions',
  'pickup.pickup_address': 'pickup.pickup_address',
  'pickup.pickup_address.name': 'pickup.pickup_address.name',
  'pickup.pickup_address.address_line1': 'pickup.pickup_address.address_line1',
  'pickup.pickup_address.address_line2': 'pickup.pickup_address.address_line2',
  'pickup.pickup_address.address_line3': 'pickup.pickup_address.address_line3',
  'pickup.pickup_address.address_line4': 'pickup.pickup_address.address_line4',
  'pickup.pickup_address.city': 'pickup.pickup_address.city',
  'pickup.pickup_address.state_province': 'pickup.pickup_address.state_province',
  'pickup.pickup_address.post_code': 'pickup.pickup_address.post_code',
  'pickup.pickup_address.country_code': 'pickup.pickup_address.country_code',
  'pickup.pickup_address.contact_email': 'pickup.pickup_address.contact_email',
  'pickup.pickup_address.contact_number': 'pickup.pickup_address.contact_number',
  'pickup.pickup_approx_volume': 'pickup.pickup_approx_volume',
  parcel_details: 'parcel_details',
  'parcel_details.weight': 'parcel_details.weight',
  'parcel_details.battery_type': 'parcel_details.battery_type',
  'parcel_details.battery_packing': 'parcel_details.battery_packing',
  'parcel_details.tax_id': 'parcel_details.tax_id',
  'parcel_details.invoice_url': 'parcel_details.invoice_url',
  'parcel_details.trade_terms': 'parcel_details.trade_terms',
  'parcel_details.customs_description': 'parcel_details.customs_description',
  'parcel_details.customs_native_description': 'parcel_details.customs_native_description',
  'parcel_details.customs_currency': 'parcel_details.customs_currency',
  'parcel_details.value': 'parcel_details.value',
  'parcel_details.hs_code': 'parcel_details.hs_code',
  'parcel_details.origin_country': 'parcel_details.origin_country',
  // For INDONESIA
  'items.description': 'items.description',
  'items.quantity': 'items.quantity',
  'items.hs_code': 'items.hs_code',
  'items.native_description': 'items.native_description',
  'items.origin_country': 'items.origin_country',
  'items.unit_value': 'items.unit_value',
  'items.unit_weight': 'items.unit_weight',
  'items.url': 'items.url'
}

export const FRIENDLY_ERROR_MESSAGE = {
  'is invalid currency code format (ISO 4217)': 'invalid_currency',
  'is invalid country code format (ISO 3166 alpha 2)': 'invalid_country_code',
  'is not a valid date format (ISO 8601)': 'invalid_date_format'
}

export const GST_KEYS = {
  NO: 'NO',
  YES: 'YES'
}

export const GST_OPTIONS = {
  [GST_KEYS.NO]: {
    label: 'no',
    key: GST_KEYS.NO
  },
  [GST_KEYS.YES]: {
    label: 'yes',
    key: GST_KEYS.YES
  }
}

export const MAX_LEN_OF_PREFIX = 5

export const SUPPORTING_DOC_TYPE = {
  COMMERCIAL_INVOICES: 1,
  DELIVERY_ORDERS: 2
}

export const SUPPORTING_DOC_TYPE_NAME = {
  [SUPPORTING_DOC_TYPE.COMMERCIAL_INVOICES]: 'commercial_invoices',
  [SUPPORTING_DOC_TYPE.DELIVERY_ORDERS]: 'delivery_orders'
}

export const ORDER_REQUEST_HEADER_FIELDS = {
  TRACKING_ID: 'tracking_id',
  TYPE: 'type',
  SHIPPER_ORDER_NUMBER: 'order_ref_no',
  RECIPIENT_NAME: 'recipient_name',
  ADDRESS: 'address',
  COD: 'cash_on_delivery',
  CUSTOMS_DESCRIPTION: 'customs_description',
  REMARK: 'remark',
  STATUS: 'status',
}